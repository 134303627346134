import { Injectable } from '@angular/core'
import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms'

export interface ErrorStateMatcher {
  isErrorVisible(
    control: AbstractControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean
}

@Injectable({ providedIn: 'root' })
export class OnTouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorVisible(
    control: AbstractControl | null,
    form: FormGroupDirective | NgForm | null
  ) {
    return Boolean(
      control &&
        control.invalid &&
        (control.touched || (form && form.submitted))
    )
  }
}

@Injectable({ providedIn: 'root' })
export class OnSubmittedErrorStateMatcher implements ErrorStateMatcher {
  isErrorVisible(
    control: AbstractControl | null,
    form: FormGroupDirective | NgForm | null
  ) {
    return Boolean(
      control && control.invalid && (control.dirty || (form && form.submitted))
    )
  }
}

@Injectable({ providedIn: 'root' })
export class OnTouchedOnlyErrorStateMatcher implements ErrorStateMatcher {
  isErrorVisible(control: AbstractControl | null) {
    return Boolean(control && control.invalid && control.touched)
  }
}
