import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function lowerThan(
  fieldName: string,
  comparerFieldName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const field = control.get(fieldName)
    const fieldToCompare = control.get(comparerFieldName)
    if (!field)
      throw new Error(`Field ${fieldName} does not exist in the control parent`)

    if (!fieldToCompare)
      throw new Error(
        `Field ${comparerFieldName} does not exist in the control parent`
      )

    if (fieldToCompare.value === null || field.value === null) return null

    if (isNaN(Number(field.value)))
      throw new Error(`Control value is not a number`)

    if (isNaN(Number(fieldToCompare.value)))
      throw new Error(`Compared field value is not a number`)

    const isLowerThan = Number(field.value) < Number(fieldToCompare.value)

    const error = isLowerThan
      ? null
      : { lowerThan: { comparedField: comparerFieldName } }

    field.setErrors(error)
    return error
  }
}
