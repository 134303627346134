import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms'

export function minArrayLength(
  min: number,
  skipFirstItem = false
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormArray) || control.value === undefined)
      return null

    if (control.length >= (skipFirstItem ? min + 1 : min)) return null
    const error = {
      minArrayLength: {
        min
      }
    }
    control.setErrors(error)
    return error
  }
}
