/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { heroPhoto } from '@ng-icons/heroicons/outline'
import { heroXMarkSolid } from '@ng-icons/heroicons/solid'

type NullableFile = File | undefined

@Component({
  selector: 'navix-input-avatar',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  templateUrl: './input-avatar.component.html',
  styleUrls: ['./input-avatar.component.scss'],
  providers: [
    provideIcons({ heroPhoto, heroXMarkSolid }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputAvatarComponent,
      multi: true
    }
  ]
})
export class InputAvatarComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  private readonly changeDetector = inject(ChangeDetectorRef)
  avatarPreview: string | undefined
  avatarLetter: string | undefined
  @ViewChild('avatarInput') avatarInput!: ElementRef

  @Input()
  initialImagePreview: string | undefined

  @Input()
  value: NullableFile = undefined

  @Input()
  disabled = false

  @Output()
  changed = new EventEmitter<File | null>()

  @Input()
  @HostBinding('attr.tabIndex')
  tabIndex = 0

  @HostListener('blur')
  onBlur() {
    this.onTouch()
  }

  ngOnInit(): void {
    this.avatarPreview = this.initialImagePreview
  }

  changeAvatar(event: Event): void {
    const element = event.currentTarget as HTMLInputElement
    const fileList: FileList | null = element.files
    if (fileList?.length === undefined) return
    const file = fileList[0]

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event: ProgressEvent<FileReader>) => {
      this.avatarPreview = event.target?.result as string
      this.setValue(file)

      this.changeDetector.markForCheck()
    }
  }

  removeAvatar() {
    this.setValue(undefined)
    this.avatarPreview = undefined
    this.avatarInput.nativeElement.value = ''
  }
  onChange: (newValue: NullableFile) => void = () => {}
  onTouch: () => void = () => {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.onChange(changes['value'].currentValue)
    }
  }
  writeValue(obj: NullableFile): void {
    this.value = obj
    this.changeDetector.markForCheck()
    this.avatarPreview = this.initialImagePreview
    if (obj !== undefined || !this.avatarInput) return
    this.avatarInput.nativeElement.value = ''
    this.avatarPreview = undefined
  }
  registerOnChange(fn: (newValue: NullableFile) => void): void {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.changeDetector.markForCheck()
  }
  setValue(value: NullableFile) {
    if (this.disabled) return
    this.value = value
    this.onChange(this.value)
    this.onTouch()
    this.changed.emit(this.value)
  }
}
