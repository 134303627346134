import { Directive, HostListener, inject } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  standalone: true,
  selector: 'input[navixNumber]'
})
export class InputNumberDirective {
  private readonly ngControl = inject(NgControl)

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const newValue = this.extractNumericPart(value)
    this.ngControl.control?.setValue(newValue)
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    const newValue = this.extractNumericPart(value)
    this.ngControl.control?.setValue(newValue)
  }

  private extractNumericPart(value: string): number | undefined {
    const numericPart = value.replace(/[^0-9]/g, '')
    return numericPart.length === 0 ? undefined : Number(numericPart)
  }
}
