/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { heroPhoto } from '@ng-icons/heroicons/outline'
import { heroXMarkSolid } from '@ng-icons/heroicons/solid'

type NullableFile = File | undefined | null

@Component({
  selector: 'navix-image-input',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
  providers: [
    provideIcons({ heroPhoto, heroXMarkSolid }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImageInputComponent,
      multi: true
    }
  ]
})
export class ImageInputComponent implements OnChanges, ControlValueAccessor {
  private readonly changeDetector = inject(ChangeDetectorRef)

  @ViewChild('imageInput') imageInput!: ElementRef

  @Input()
  imagePreview: string | undefined
  @Output()
  imagePreviewChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >()

  @Input()
  inputWarning: string | undefined =
    'The maximum file size allowed is 64KB. For best results, image dims should be 150x150.'

  @Input()
  value: NullableFile = undefined

  @Input()
  disabled = false

  @Output()
  changed = new EventEmitter<File | null>()

  @Input()
  @HostBinding('attr.tabIndex')
  tabIndex = 0

  @Input()
  accept = ''

  @HostListener('blur')
  onBlur() {
    this.onTouch()
  }

  changeAvatar(event: Event): void {
    const element = event.currentTarget as HTMLInputElement
    const fileList: FileList | null = element.files
    if (fileList?.length === undefined) return
    const file = fileList[0]

    this.setValue(file)
    this.changePreview(file)
    this.changeDetector.markForCheck()
  }

  private changePreview(file: File) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (this.value === undefined) {
        this.emptyPreview()
        return
      }
      this.imagePreview = event.target?.result as string
      this.imagePreviewChange.emit(this.imagePreview)
    }
  }

  removeAvatar() {
    this.setValue(undefined)
    this.emptyPreview()
  }
  onChange: (newValue: NullableFile) => void = () => {}
  onTouch: () => void = () => {}

  private emptyPreview() {
    this.imagePreview = undefined
    this.imagePreviewChange.emit(this.imagePreview)

    if (this.imageInput) {
      this.imageInput.nativeElement.value = ''
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.onChange(changes['value'].currentValue)
    }
  }
  writeValue(obj: NullableFile): void {
    this.value = obj
    this.changeDetector.markForCheck()

    if (this.value !== undefined && this.value !== null) {
      this.changePreview(this.value)
    } else {
      this.emptyPreview()
    }
  }
  registerOnChange(fn: (newValue: NullableFile) => void): void {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.changeDetector.markForCheck()
  }
  setValue(value: NullableFile) {
    if (this.disabled) return
    this.value = value
    this.onChange(this.value)
    this.onTouch()
    this.changed.emit(this.value)
  }
}
