import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CommonModule, KeyValue } from '@angular/common'
import { ValidationErrors } from '@angular/forms'
import { ErrorMessagePipe } from '../error-message/error-message.pipe'

@Component({
  selector: 'navix-input-error',
  standalone: true,
  imports: [CommonModule, ErrorMessagePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <small
      *ngFor="let error of errors | keyvalue; trackBy: trackByFn"
      class="invalid-feedback block">
      {{ error.key | errorMessage : error.value }}
    </small>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ]
})
export class InputErrorComponent {
  @Input()
  errors: ValidationErrors | undefined | null = null

  trackByFn(index: number, item: KeyValue<string, any>) {
    return item.key
  }
}
