<div class="flex items-center flex-nowrap gap-5 my-2">
  <input
    type="file"
    #imageInput
    [accept]="accept"
    (change)="changeAvatar($event)"
    class="mt-2 text-sm text-grey-500 file:mr-5 file:py-2 file:px-2 file:rounded-md file:border file:text-xs file:font-medium file:bg-transparent file:border-main file:text-main cursor-pointer hidden" />
  <button
    class="btn !h-[34px] p-2 btn-outline btn-primary text-xs text-grey-500 rounded-md"
    (click)="imageInput.click()"
    type="button">
    Choose File
  </button>
  <span class="text-sm">
    {{ value?.name }}
  </span>
</div>

<small class="text-gray-500 mt-2">
  {{ inputWarning }}
</small>
