<article class="flex flex-wrap md:flex-nowrap gap-4 py-4">
  <div class="flex relative items-center" *ngIf="!avatarPreview">
    <div class="avatar">
      <div
        class="w-24 rounded-full"
        [ngClass]="{
          'bg-gray-200 border-gray-300': !avatarLetter,
          'bg-main border-main': avatarLetter
        }">
        <div class="relative items-center justify-center flex h-full">
          <ng-icon size="20" name="heroPhoto" *ngIf="!avatarLetter"></ng-icon>
          <span
            class="text-white capitalize font-semibold text-xl"
            *ngIf="avatarLetter"
            >{{ avatarLetter }}</span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="avatar" *ngIf="avatarPreview">
    <div class="w-24 h-24 border border-neutral rounded-full">
      <img [src]="avatarPreview" alt="Avatar preview image" />
    </div>
    <button
      *ngIf="avatarPreview"
      class="absolute top-1 right-1 rounded-full w-5 h-5 bg-gray-500"
      (click)="removeAvatar()">
      <ng-icon class="text-white" name="heroXMarkSolid"></ng-icon>
    </button>
  </div>

  <div class="flex flex-col gap-4 w-full overflow-auto">
    <span class="text-sm font-medium">Upload new avatar</span>

    <div class="flex items-center flex-nowrap gap-5">
      <input
        id="avatar-file"
        type="file"
        #avatarInput
        accept="image/gif, image/jpeg, image/png"
        (change)="changeAvatar($event)"
        class="hidden" />
      <button
        class="btn !h-[34px] p-2 btn-outline btn-primary text-xs text-grey-500 rounded-md"
        (click)="avatarInput.click()"
        type="button">
        Choose File
      </button>
      <span class="text-sm">
        {{ value?.name }}
      </span>
    </div>

    <small class="text-gray-500 mt-2">
      The maximum file size allowed is 64KB. For best results, image dims should
      be 150x150.
    </small>
  </div>
</article>
