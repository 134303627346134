import { dateGreaterThan } from './validators/date-greater-than.validator'
import { dateLowerThan } from './validators/date-lower-than.validator'
import { greaterThan } from './validators/greater-than.validator'
import { lowerThan } from './validators/lower-than.validator'
import { maxFileSize } from './validators/max-file-size.validator'
import { minArrayLength } from './validators/min-array-length.validator'
import { duplicate } from './validators/duplicate.validator'
import { validJson } from './validators/valid-json.validator'
import { requiredIf } from './validators/required-if.validator'

export const NavixValidators = {
  maxFileSize,
  greaterThan,
  lowerThan,
  dateGreaterThan,
  dateLowerThan,
  minArrayLength,
  duplicate,
  validJson,
  requiredIf
}

export * from './input-avatar/input-avatar.component'
export * from './dynamic-validator-message/dynamic-validator-message.directive'
export * from './input-error/validation-error-messages.token'
export * from './input-error/validator-message-container.directive'
export * from './input-error/error-state-matcher.service'

export * from './input-types/input-number.directive'

export * from './image-input/image-input.component'
