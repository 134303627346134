import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms'

export function duplicate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null

    const formArray = control.parent as FormArray
    if (!formArray) return null

    const duplicateError = { duplicate: true }
    const valueToCheck = control.value

    const firstIndex = formArray.controls.findIndex(
      x => x.value === valueToCheck
    )

    if (firstIndex !== -1) {
      const lastIndex = formArray.controls
        .map(obj => obj.value)
        .lastIndexOf(valueToCheck)

      if (firstIndex !== lastIndex) return duplicateError
    }

    return null
  }
}
