/* eslint-disable @angular-eslint/directive-selector */
import {
  ComponentRef,
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core'
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  NgForm,
  NgModel
} from '@angular/forms'
import {
  EMPTY,
  fromEvent,
  iif,
  merge,
  skip,
  startWith,
  Subscription
} from 'rxjs'
import {
  ErrorStateMatcher,
  OnTouchedErrorStateMatcher
} from '../input-error/error-state-matcher.service'
import { InputErrorComponent } from '../input-error/input-error.component'
import { InjectionToken } from '@angular/core'

export const STATE_MATCHER = new InjectionToken<ErrorStateMatcher>(
  'STATE_MATCHER'
)

@Directive({
  selector: `
    [ngModel]:not([withoutValidationErrors]),
    [formControl]:not([withoutValidationErrors]),
    [formControlName]:not([withoutValidationErrors]),
    [formGroupName]:not([withoutValidationErrors]),
    [ngModelGroup]:not([withoutValidationErrors]),
    [formArray]:not([withoutValidationErrors]),
    [formArrayName]:not([withoutValidationErrors]),
  `,
  standalone: true
})
export class DynamicValidatorMessageDirective implements OnInit, OnDestroy {
  ngControl =
    inject(NgControl, { self: true, optional: true }) ||
    inject(ControlContainer, { self: true })
  elementRef = inject(ElementRef)
  get form() {
    return this.parentContainer?.formDirective as
      | NgForm
      | FormGroupDirective
      | null
  }
  @Input()
  errorStateMatcher =
    inject(STATE_MATCHER, { optional: true }) ||
    inject(OnTouchedErrorStateMatcher)

  @Input()
  container = inject(ViewContainerRef)

  private componentRef: ComponentRef<InputErrorComponent> | null = null
  private errorMessageTrigger!: Subscription
  private parentContainer = inject(ControlContainer, { optional: true })

  ngOnInit() {
    queueMicrotask(() => {
      if (!this.ngControl.control)
        throw Error(`No control model for ${this.ngControl.name} control...`)
      this.errorMessageTrigger = merge(
        this.ngControl.control.statusChanges,
        iif(
          () => !!this.ngControl.control?.parent,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.ngControl.control.parent!.statusChanges,
          EMPTY
        ),
        fromEvent(this.elementRef.nativeElement, 'blur'),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        iif(() => !!this.form, this.form!.ngSubmit, EMPTY)
      )
        .pipe(
          startWith(this.ngControl.control.status),
          skip(this.ngControl instanceof NgModel ? 1 : 0)
        )
        .subscribe(() => {
          if (
            this.errorStateMatcher.isErrorVisible(
              this.ngControl.control,
              this.form
            )
          ) {
            if (!this.componentRef) {
              this.componentRef =
                this.container.createComponent(InputErrorComponent)
              this.componentRef.changeDetectorRef.markForCheck()
            }
            this.componentRef.setInput('errors', this.ngControl.errors)
          } else {
            this.componentRef?.destroy()
            this.componentRef = null
          }
        })
    })
  }
  ngOnDestroy() {
    this.errorMessageTrigger.unsubscribe()
  }
}
