import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { isBefore, isValid } from 'date-fns'

export function dateLowerThan(
  fieldName: string,
  comparerFieldName: string,
  displayComparedFieldName?: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const field = control.get(fieldName)
    const fieldToCompare = control.get(comparerFieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} does not exist in the control parent`)
    }

    if (!fieldToCompare) {
      throw new Error(
        `Field ${comparerFieldName} does not exist in the control parent`
      )
    }

    if (!field.value || !fieldToCompare.value) {
      return null
    }

    const parsedFieldValue = new Date(field.value)
    const parsedFieldToCompareValue = new Date(fieldToCompare.value)

    if (!isValid(parsedFieldValue) || !isValid(parsedFieldToCompareValue)) {
      return null
    }

    if (isBefore(parsedFieldValue, parsedFieldToCompareValue)) {
      const newErrors = { ...field.errors }
      delete newErrors?.['dateLowerThan']
      const fixedNewErrors =
        Object.keys(newErrors).length > 0 ? newErrors : null
      field.setErrors(fixedNewErrors)
      return null
    }

    const error = {
      dateLowerThan: {
        comparedField: displayComparedFieldName ?? comparerFieldName
      }
    }
    field.setErrors(error)
    return error
  }
}
