import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
const MAX_FILE_SIZE: number = 64 as const
const TO_KB_MULTIPLIER: number = 1024 as const

export function maxFileSize(
  maxFileSizeInKb: number = MAX_FILE_SIZE
): ValidatorFn {
  const _maxFileSize = maxFileSizeInKb * TO_KB_MULTIPLIER
  return (control: AbstractControl<File | null>): ValidationErrors | null => {
    if (control.value === undefined) return null
    const isValid = (control?.value?.size ?? 0) < _maxFileSize

    return isValid
      ? null
      : {
          maxFileSize: {
            fileSize: control.value?.size,
            maxFileSizeInKb
          }
        }
  }
}
