import { InjectionToken } from '@angular/core'

export const ERROR_MESSAGES: { [key: string]: (args?: any) => string } = {
  required: () => `This field is required`,
  requiredTrue: () => `This field is required`,
  email: () => `It should be a valid email`,
  minlength: ({ requiredLength }) =>
    `The length should be at least ${requiredLength} characters`,
  pattern: () => `Wrong format`,
  mask: ({ requiredMask }) => `Required format: ${requiredMask}.`,
  greaterThan: ({ comparedField }) =>
    `This field is less than or equal than ${comparedField} field`,
  lowerThan: ({ comparedField }) =>
    `This field is greater than ${comparedField} field`,
  dateGreaterThan: ({ comparedField }) =>
    `This date is less than or equal than ${comparedField} field`,
  dateLowerThan: ({ comparedField }) =>
    `This date is greater or equal than ${comparedField} field`,
  minArrayLength: ({ min }) =>
    `Size of list should be greater or equal than ${min}`,
  duplicate: () => `This field is duplicated`,
  matDatepickerMax: ({ max }) =>
    `Date should be prior ${max.toLocaleDateString()}`,
  matDatepickerMin: ({ min }) =>
    `Date should be after than ${min.toLocaleDateString()}`,
  invalidJson: () => `Invalid JSON`
}

export const VALIDATION_ERROR_MESSAGES = new InjectionToken(
  `Validation Messages`,
  {
    providedIn: 'root',
    factory: () => ERROR_MESSAGES
  }
)
