import { AbstractControl, ValidationErrors } from '@angular/forms'

export function validJson(control: AbstractControl): ValidationErrors | null {
  try {
    JSON.parse(control.value)
    return null
  } catch (e) {
    return {
      invalidJson: true
    }
  }
}
