import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function requiredIf(
  fieldName: string,
  comparerFieldName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const field = control.get(fieldName)
    const fieldToCompare = control.get(comparerFieldName)

    if (!field) {
      throw new Error(`Field ${fieldName} does not exist in the control parent`)
    }

    if (!fieldToCompare) {
      throw new Error(
        `Field ${comparerFieldName} does not exist in the control parent`
      )
    }

    const comparerHasValue =
      fieldToCompare.value !== null &&
      fieldToCompare.value !== undefined &&
      fieldToCompare.value !== ''
    const fieldHasValue =
      field.value !== null && field.value !== undefined && field.value !== ''
    if (comparerHasValue && !fieldHasValue)
      field.setErrors({
        required: true
      })

    return null
  }
}
